import React from 'react';
import PropTypes from 'prop-types';
import { Link, NavLink } from 'react-router-dom';

// Assets
import { ReactComponent as FavoritesIcon } from '../../../../assets/images/svg/favorites-icon.svg';
import { ReactComponent as SubscribeIcon } from '../../../../assets/images/svg/subscribe.svg';
import './ToolBar.scss';
import { useTranslation } from 'react-i18next';

import { GROUP_NAME_EXTERNAL } from '../../../../constants/configuration.js';

// Components
import SearchBarContainer from '../../../../containers/SearchBarContainer';
import AvatarContainer from '../../../../containers/AvatarContainer';
import ProfileDropdownContainer from '../../../../containers/ProfileDropdownContainer';

const ToolBar = ({ isAuthorized, location, isDesktop, user }) => {
  const { t } = useTranslation();

  return (
    <ul className="ToolBar">
      {isAuthorized && (
        <li className="ToolBar__item ToolBar__favorites">
          <NavLink to="/favorites">
            <FavoritesIcon />
            <span>{t('header.toolbar.watch')}</span>
          </NavLink>
        </li>
      )}
      {isAuthorized && isDesktop && user?.groups?.[0].name === GROUP_NAME_EXTERNAL && (
        <li className="ToolBar__item ToolBar__subscribe">
          <Link to="/subscribe">
            <SubscribeIcon />
            <span>{t('header.toolbar.subscribe')}</span>
          </Link>
        </li>
      )}
      <li className="ToolBar__search">
        <SearchBarContainer />
      </li>
      <li className="ToolBar__login">
        {isAuthorized ? (
          <>
            {isDesktop ? (
              <>
                <Link className="ToolBar__avatar" to="/profile">
                  <AvatarContainer />
                </Link>
                <ProfileDropdownContainer />
              </>
            ) : (
              <Link className="ToolBar__avatar" to="/profile">
                <AvatarContainer />
              </Link>
            )}
          </>
        ) : (
          <Link
            to={{
              pathname: '/auth/login',
              state: { background: location }
            }}
          >
            {t('header.loginBtn')}
          </Link>
        )}
      </li>
    </ul>
  );
};

ToolBar.propTypes = {
  isDesktop: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,
  isAuthorized: PropTypes.bool.isRequired,
  statusIsAuthorized: PropTypes.bool.isRequired,
  location: PropTypes.object.isRequired,
  user: PropTypes.object
};

export default ToolBar;
