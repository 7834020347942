import React, { useState } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import Parser from 'html-react-parser';
import { useTranslation } from 'react-i18next';
import { throttle } from '../../../helpers/utils';
import { track } from '@amplitude/analytics-browser';
import { USER_CABINET_URL } from '../../../constants/configuration';

// Assets
import './../Form.scss';
import './LoginForm.scss';
import { ReactComponent as LockSvg } from '../../../assets/images/svg/lock-form.svg';
import { ReactComponent as CheckBoxSvg } from '../../../assets/images/svg/check-box.svg';
import { toast } from 'react-toastify';

// Form stuff
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';

import {
  // captchaValidation,
  loginPasswordValidation,
  loginValidation
} from '../../validation';
import { getFormattedLoginValue } from '../../../helpers/utils';
import {
  ACCOUNT_NOT_CONFIRMED_ERROR,
  TOO_MANY_REQUESTS_ERROR
} from '../../../constants/configuration';

// Components
// import ReCaptcha from '../../form-items/ReCaptcha';
import AuthModalContainer from '../../../containers/AuthModalContainer';
import TextInputComponent from '../../form-items/TextInputComponent';
import PasswordInputComponent from '../../form-items/PasswordInputComponent';
import Checkbox from '../../form-items/Checkbox';

// Redux stuff
import { useDispatch } from 'react-redux';
import { login } from '../../../../features/auth/authSlice';
import { setUserDataProfile } from '../../../../features/user/userSlice';
import { unwrapResult } from '@reduxjs/toolkit';

const LoginForm = () => {
  const { t, i18n } = useTranslation();

  // const recaptchaRef = React.createRef();

  const [isLoginConfirmationNeeded, setIsLoginConfirmationNeeded] = useState(false);

  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();

  const backgroundLocation = location.state ? location.state.background : '/';
  const goToConfirmForm = (values) => {
    history.push({
      pathname: '/auth/confirm',
      state: {
        background: backgroundLocation,
        login: values.login
      }
    });
  };

  const unfreezeField = (e) => {
    const field = e.target.closest('.Form__group').querySelector('input');
    const {value} = field;
    field.value = value;
  };

  // Schema for yup
  const validationSchema = Yup.object({
    login: loginValidation().trim(),
    password: loginPasswordValidation(),
    remember: Yup.boolean()
    // captcha: captchaValidation
  });

  return (
    <AuthModalContainer
      title={t('auth.loginForm.title')}
      socials={true}
      description={Parser(t('auth.loginForm.subtitle'))}
    >
      <Formik
        initialValues={{
          login: '',
          password: '',
          remember: true
        }}
        validationSchema={validationSchema}
        onSubmit={throttle(async (values, { setSubmitting, setErrors }) => {
          try {
            const {
              data: { user }
            } = await unwrapResult(
              await dispatch(
                login({
                  login: getFormattedLoginValue(values?.login).replaceAll(' ', ''),
                  password: values?.password,
                  remember: values?.remember
                })
              )
            );

            if (user) {
              dispatch(setUserDataProfile(user));
              const userInfo = {
                UserId: user.id,
                UserLogin: `${user.email}, ${user.phone}`,
                UserName: user.name,
                AccountType: user.groups?.[0].name
              };

              track('Logged In', userInfo);
            }

            toast.success(t('notifications.LOGIN_SUCCESS'));
            history.push(backgroundLocation);
          } catch (err) {
            // handle 430 error
            if (err.message === TOO_MANY_REQUESTS_ERROR) {
              setIsLoginConfirmationNeeded(false);
              setErrors({ login: t('notifications.MANY_REQUEST_ERROR'), password: true });
              toast.error(t('notifications.MANY_REQUEST_ERROR'));
              track('Error Encountered', {
                ErrorType: 'login form',
                ErrorName: t('notifications.MANY_REQUEST_ERROR')
              });
            } else if (err.message === ACCOUNT_NOT_CONFIRMED_ERROR) {
              setIsLoginConfirmationNeeded(true);
              toast.error(t('notifications.LOGIN_NOT_CONFIRMED'));
              track('Error Encountered', {
                ErrorType: 'login form',
                ErrorName: t('notifications.LOGIN_NOT_CONFIRMED')
              });
            } else {
              setIsLoginConfirmationNeeded(false);
              setErrors({ login: t('notifications.LOGIN_NOTFOUND'), password: true });
              toast.error(t('notifications.LOGIN_NOTFOUND'));
              track('Error Encountered', {
                ErrorType: 'login form',
                ErrorName: t('notifications.LOGIN_NOTFOUND')
              });
            }
            setSubmitting(false);
          }
        }, 3000)}
      >
        {({ errors, values }) => (
          <Form className="Form LoginForm">
            <div className="Form__group">
              <Field
                name="login"
                className={`text-field__input`}
                label={t('auth.loginForm.loginInputPlaceholder')}
                component={TextInputComponent}
                onClick={unfreezeField}
              />
              <div className="i-m-error-wrap">
                <ErrorMessage name="login" component="span" className="i-m-error" />
                {!errors.login && isLoginConfirmationNeeded && (
                  <span className="i-m-error">
                    {t('notifications.LOGIN_NOT_CONFIRMED')}{' '}
                    <button type="button" onClick={() => goToConfirmForm(values)}>
                      {t('auth.loginForm.confirmBtnText')}
                    </button>
                  </span>
                )}
              </div>
            </div>
            <div className="Form__group">
              <Field
                name={'password'}
                id={'password'}
                className={`text-field__input`}
                label={'Пароль'}
                component={PasswordInputComponent}
                onClick={unfreezeField}
              />
              <div className="i-m-error-wrap">
                <ErrorMessage name="password" component="span" className="i-m-error" />
              </div>
            </div>
            <div className="Form__group Form__options">
              <div className="Form__option">
                <Checkbox name="remember" id="remember-me">
                  <label htmlFor="remember-me">
                    <span className="checkmark">
                      <CheckBoxSvg />
                    </span>
                    {t('auth.loginForm.rememberMeText')}
                  </label>
                </Checkbox>
              </div>
              <div className="Form__option">
                <span>
                  <LockSvg />
                </span>
                <Link
                  className="l-forgot-pass"
                  to={{
                    pathname: '/auth/password-resend',
                    state: { background: backgroundLocation }
                  }}
                >
                  {t('auth.loginForm.forgotPasswordText')}
                </Link>
              </div>
            </div>
            {/* --- ReCaptcha will be here --- */}
            <div className="Form__group">
              <button className="Btn Btn--primary" type="submit">
                {t('auth.loginForm.submitBtnText')}
              </button>
              <a
                target="_blank"
                className="Btn Btn--primary register-link"
                href={`${USER_CABINET_URL}?lang=${i18n.language}`}
                rel="noreferrer"
              >
                {t('auth.loginForm.register')}
              </a>
            </div>
            {/* <div className="Form__text Form__text-bottom">
              <Link
                className="reg-link"
                to={{
                  pathname: '/auth/register',
                  state: { background: backgroundLocation }
                }}
              >
                {t('auth.loginForm.register')}
              </Link>
              <span>&nbsp; {t('auth.loginForm.newAccount')}</span>
            </div> */}
          </Form>
        )}
      </Formik>
    </AuthModalContainer>
  );
};

export default LoginForm;
