import { client } from './api-client';

// Promo codes validation
export const getValidationSubscriptionsPromoCode = (body) =>
  client.post(`subscriptions/promocode`, body);
export const getValidationVideoPromoCode = (body) => client.post(`movie/promocode`, body);

// User billing
export const mggBuyVideo = (body) => client.post(`buy/movie`, body);
export const mggBuySubscription = (body) => client.post(`buy/subscription`, body);
export const mggUnsubscribe = (body) => client.post(`unsubscribe`, body);
export const fetchPaymentCards = () => client.get(`cards`);
export const addPaymentCard = (body) => client.post(`cards`, body);
export const removePaymentCard = (body) => client.delete(`cards`, body);
export const fetchPaymentTransactions = (limit, offset) => {
  const _limit = limit ? `&limit=${limit}` : '';
  const _offset = offset ? `&offset=${offset}` : '';

  return client.get(`transactions?${_limit}${_offset}`);
};
