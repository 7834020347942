// import React, { Suspense } from 'react';
import React from 'react';
import PropTypes from 'prop-types';
// import SocialButton from '../../form-items/SocialButton/SocialButton';

// Assets
import './Modal.scss';
// import { ReactComponent as FacebookSvg } from '../../../assets/images/svg/soc-form-f.svg';
// import { ReactComponent as GoogleSvg } from '../../../assets/images/svg/soc-form-g.svg';
import { ReactComponent as CloseSvg } from '../../../assets/images/svg/close-black.svg';
// import Loader from '../Loader';
// import { useTranslation } from 'react-i18next';

// const SocialButton = lazy(() => import('../../form-items/SocialButton/SocialButton'));

const Modal = ({
  modalClassName,
  title,
  description,
  // socials,
  onModalClose,
  // onSocialLoginSuccess,
  // onSocialLoginFailure,
  children
  // googleAppId,
  // fbAppId
}) => {
  // const { t } = useTranslation();

  const closeBtn = (
    <span className="Modal__close" onClick={onModalClose}>
      <CloseSvg />
    </span>
  );

  return (
    <div className="Modal__wrap">
      <div className={`Modal ${modalClassName}`}>
        {closeBtn}
        {title && <div className="Modal__title">{title}</div>}
        {description && <div className="Modal__text Modal__description">{description}</div>}

        <div className="Modal__content">{children}</div>

        {/* {socials && (
        <div className="Modal__socials">
          <p className="Modal__text">{t('auth.socials.text')}</p>
          <Suspense fallback={<Loader />}>
            <SocialButton
              className="Modal__socials-btn"
              provider="facebook"
              appId={fbAppId}
              onLoginSuccess={(user) => onSocialLoginSuccess(user)}
              onLoginFailure={(err) => onSocialLoginFailure(err)}
            >
              <FacebookSvg />
              <span>Facebook</span>
            </SocialButton>
            <SocialButton
              className="Modal__socials-btn"
              provider="google"
              appId={googleAppId}
              onLoginSuccess={(user) => onSocialLoginSuccess(user)}
              onLoginFailure={(err) => onSocialLoginFailure(err)}
            >
              <GoogleSvg />
              <span>Google</span>
            </SocialButton>
          </Suspense>
        </div>
      )} */}
      </div>
    </div>
  );
};

Modal.propTypes = {
  modalClassName: PropTypes.string,
  fbAppId: PropTypes.string,
  googleAppId: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string,
  socials: PropTypes.bool,
  onModalClose: PropTypes.func,
  onSocialLoginSuccess: PropTypes.func,
  onSocialLoginFailure: PropTypes.func,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.element).isRequired,
    PropTypes.element.isRequired
  ]).isRequired
};

export default Modal;
