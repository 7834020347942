import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import PortalContainer from '../PortalContainer';
import './RocketLogContainer.scss';

const RocketLogContainer = ({ isShow }) => {
  const { t } = useTranslation();

  const isInIframe = () => {
    return (window.location !== window.parent.location);
  };

  return (
    <PortalContainer className="rocket-log-wrap">
      {isShow && !isInIframe() ? <div className="rocket-log">{t('logRocketText')}</div> : null}
    </PortalContainer>
  );
};

RocketLogContainer.propTypes = {
  isShow: PropTypes.bool
};

export default RocketLogContainer;
