import React from 'react';
import PropTypes from 'prop-types';

// Components
import Header from '../../components/common/Header';
import Footer from '../../components/common/Footer';

const AppLayout = ({ children }) => {
  return (
    <div id="AppContainer">
      <Header />
      <main>{children}</main>
      <Footer />
    </div>
  );
};

export default AppLayout;

AppLayout.propTypes = {
  children: PropTypes.node.isRequired
};
