import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { IconButton, InputAdornment, TextField } from '@mui/material';
import { ReactComponent as HideEyeSvg } from '../../../assets/images/svg/hidePassword.svg';
import { ReactComponent as ShowEyeSvg } from '../../../assets/images/svg/showPassword.svg';
import { useTranslation } from 'react-i18next';

const PasswordInputComponent = ({ field, form: { touched, errors }, ...props }) => {
  const { t } = useTranslation();

  const [formState, setFormState] = useState({
    passwordType: 'password',
    passwordTitle: t('auth.passwordConfirmForm.showPassword')
  });

  const toggleInputType = () => {
    setFormState({
      ...formState,
      passwordType: formState.passwordType === 'password' ? 'text' : 'password',
      passwordTitle:
        formState.passwordType === 'password'
          ? t('auth.passwordConfirmForm.hidePassword')
          : t('auth.passwordConfirmForm.showPassword')
    });
  };
  return (
    <TextField
      variant="filled"
      fullWidth
      autoComplete="off"
      type={formState.passwordType}
      {...props}
      {...field}
      error={touched[field.name] && Boolean(errors[field.name])}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={toggleInputType}
              edge="end"
            >
              {formState.passwordType !== 'password' ? (
                <HideEyeSvg className="show-password" title={formState.passwordTitle} />
              ) : (
                <ShowEyeSvg className="hide-password" title={formState.passwordTitle} />
              )}
            </IconButton>
          </InputAdornment>
        )
      }}
    />
  );
};

PasswordInputComponent.propTypes = {
  field: PropTypes.shape({ name: PropTypes.string }),
  form: PropTypes.shape({
    touched: PropTypes.shape({}),
    errors: PropTypes.shape({})
  })
};

export default PasswordInputComponent;
