import React from 'react';
import PropTypes from 'prop-types';
import { useField } from 'formik';

const Checkbox = ({ children, ...props }) => {
  const [field, meta] = useField({ ...props, type: 'checkbox' });

  return (
    <>
      <input type="checkbox" {...field} {...props} />
      {children}
      {meta.touched && meta.error ? <div className="error">{meta.error}</div> : null}
    </>
  );
};

Checkbox.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.element).isRequired,
    PropTypes.element.isRequired
  ]).isRequired
};

export default Checkbox;
