import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { FETCH_STATUS_FAILED, FETCH_STATUS_LOADING, FETCH_STATUS_SUCCESS } from '../constants';
import { mggFetchConfiguration } from '../../api/videoConfigurationApi';
import { createSelector } from 'reselect';

const initialState = {
  configuration: {
    status: 'idle',
    categories: [],
    subscriptions: [],
    categoryId: null,
    genres: [],
    yearsFilters: [],
    geo: ''
  }
};

export const fetchVideoConfiguration = createAsyncThunk(
  'videoConfiguration/fetchVideoConfiguration',
  async () => {
    return await mggFetchConfiguration();
  }
);

const videConfigurationSlice = createSlice({
  name: 'videoConfiguration',
  initialState,
  reducers: {},
  extraReducers: {
    [fetchVideoConfiguration.pending]: (state) => {
      state.configuration.status = FETCH_STATUS_LOADING;
    },
    [fetchVideoConfiguration.fulfilled]: (state, action) => {
      state.configuration.status = FETCH_STATUS_SUCCESS;
      state.configuration.categories = action.payload.data.categories;
      state.configuration.genres = action.payload.data.genres.map(({ id, path, title }) => {
        return { id, path, title };
      });
      state.configuration.yearsFilters = action.payload.data.video_year_filters;
      state.configuration.subscriptions = action.payload.data.video_briz_product_filters;
      state.configuration.geo = action.payload.data.geo;
    },
    [fetchVideoConfiguration.rejected]: (state) => {
      state.configuration.status = FETCH_STATUS_FAILED;
    }
  }
});

// Selectors
export const getConfigurationLoadingStatus = (state) =>
  state.videoConfiguration.configuration.status;
export const getConfigurationGenres = (state) => state.videoConfiguration.configuration.genres;
export const getConfigurationCategories = (state) =>
  state.videoConfiguration.configuration.categories;
export const getConfigurationYears = (state) => {
  return [...state.videoConfiguration.configuration.yearsFilters].sort(
    (a, b) => b.year_to - a.year_to
  );
};
export const getConfigurationSubscriptions = (state) =>
  state.videoConfiguration.configuration.subscriptions;
export const getConfigurationGeo = (state) => state.videoConfiguration.configuration.geo;
export const getGenresByCategoryId = (state, categoryId) =>
  createSelector([getConfigurationCategories], (state) => {
    const genres = state
      ?.filter((item) => item.id == categoryId)
      .map((item) => item.genres)
      .flat(1);
    if (categoryId == 16) {
      return genres.filter((i) => {
        if (i != 41546 && i != 23361 && i != 37 && i != 41015 && i != 41005) {
          return i;
        }
      });
    }
    if (categoryId == 4) {
      return genres.filter((i) => {
        if (
          i != 41546 &&
          i != 17 &&
          i != 174 &&
          i != 39495 &&
          i != 37 &&
          i != 39431 &&
          i != 41015 &&
          i != 15
        ) {
          return i;
        }
      });
    }
    if (categoryId == 9) {
      return genres.filter((i) => {
        if (
          i != 180 &&
          i != 721 &&
          i != 11171 &&
          i != 25901 &&
          i != 39471 &&
          i != 40655 &&
          i != 40725 &&
          i != 40735 &&
          i != 40965 &&
          i != 40995
        ) {
          return i;
        }
      });
    }
    return genres.filter((i) => i != 41546 && i != 17 && i != 174 && i != 39495);
  });

export default videConfigurationSlice.reducer;
