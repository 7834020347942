import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
  fetchSubscriptionsProducts,
  fetchSubscriptionsProductsWithChannels
} from '../../api/subscriptionsApi';
import { FETCH_STATUS_FAILED, FETCH_STATUS_LOADING, FETCH_STATUS_SUCCESS } from '../constants';

const initialState = {
  subscriptionsList: {
    data: [],
    status: 'idle',
    error: null
  },
  subscriptionsWithChannels: {
    data: [],
    status: 'idle',
    error: null
  }
};

export const fetchSubscriptions = createAsyncThunk('subscriptions/fetchSubscriptions', async () => {
  return await fetchSubscriptionsProducts();
});

export const fetchSubscriptionsWithChannels = createAsyncThunk(
  'subscriptions/fetchSubscriptionsWithChannels',
  async () => {
    return await fetchSubscriptionsProductsWithChannels();
  }
);

const subscriptionsSlice = createSlice({
  name: 'subscriptions',
  initialState,
  reducers: {
    resetSubscriptionsWithChannels: (state) => {
      state.subscriptionsWithChannels = initialState.subscriptionsWithChannels;
    }
  },
  extraReducers: {
    [fetchSubscriptions.pending]: (state) => {
      state.subscriptionsList.status = FETCH_STATUS_LOADING;
    },
    [fetchSubscriptions.fulfilled]: (state, action) => {
      state.subscriptionsList.status = FETCH_STATUS_SUCCESS;
      state.subscriptionsList.data = action.payload.data.sort((a, b) => a.price - b.price);
    },
    [fetchSubscriptions.rejected]: (state, action) => {
      state.subscriptionsList.status = FETCH_STATUS_FAILED;
      state.subscriptionsList.error = action.payload;
    },
    [fetchSubscriptionsWithChannels.pending]: (state) => {
      state.subscriptionsWithChannels.status = FETCH_STATUS_LOADING;
    },
    [fetchSubscriptionsWithChannels.fulfilled]: (state, action) => {
      state.subscriptionsWithChannels.status = FETCH_STATUS_SUCCESS;
      state.subscriptionsWithChannels.data = action.payload.data;
    },
    [fetchSubscriptionsWithChannels.rejected]: (state, action) => {
      state.subscriptionsWithChannels.status = FETCH_STATUS_FAILED;
      state.subscriptionsWithChannels.error = action.payload;
    }
  }
});

// Selectors
export const getSubscriptionsList = (state) => state.subscriptions.subscriptionsList.data;
export const getSubscriptionsListStatus = (state) => state.subscriptions.subscriptionsList.status;
export const getSubscriptionsWithChannelsList = (state) =>
  state.subscriptions.subscriptionsWithChannels.data;
export const getSubscriptionsWithChannelsStatus = (state) =>
  state.subscriptions.subscriptionsWithChannels.status;

export const { resetSubscriptionsWithChannels } = subscriptionsSlice.actions;

export default subscriptionsSlice.reducer;
